/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { debounce } from "debounce";
import {
  ApplicationView,
  ButtonComponent,
  Loader,
  PaginationComponent,
  LeadsView,
} from "../Components";

import {
  Container,
  FilterBtn,
  HeaderText,
  PaginationSection,
  PaginationSectionText,
  SearchBar,
} from "./styled/students";
import icon from "../Assets/Icons/SearchIcon.svg";
import { FilterIcon } from "../Assets";
import { getLeads, searchLeads } from "../Services/Function";
import ModalComponent from "../Components/Modal";
import { Dropdown } from "react-bootstrap";
import { LabelText } from "../Components/DetailModal/styled";
// const ListView = React.lazy(() => import("../Components/ListView/index"));

interface ILeadsProps {}

export const Leads: React.FunctionComponent<ILeadsProps> = (props) => {
  const auth = useSelector((state: any) => state.auth);
  var authToken = localStorage.getItem("authToken");
  const [listView, setlistView] = React.useState(false);
  const [studentsQualified, setstudentsQualified] = React.useState([]);
  const [studentsUnqualified, setstudentsUnqualified] = React.useState([]);
  const [searchText, setsearchText] = React.useState("");
  const [page, setpage] = React.useState(1);
  const [totalPages, settotalPages] = React.useState("");
  const [qualified, setqualified] = React.useState(true);
  const [totalResults, settotalResults] = React.useState<any>(0);
  const [filterModal, setfilterModal] = React.useState(false);
  const [selectedDegree, setselectedDegree] = React.useState<any>("");
  const [selectedCountry, setselectedCountry] = React.useState<any>("");
  const [filterApplied, setfilterApplied] = React.useState(false);
  const [selectedResidence, setselectedResidence] = React.useState("");
  const [selectedAd, setselectedAd] = React.useState("");
  const [adBudget, setadBudget] = React.useState(0);
  const [selectedStatus, setselectedStatus] = React.useState<any>("");
  const [selectedSource, setselectedSource] = React.useState<any>("");

  const [startDate, setstartDate] = React.useState(null);
  const [endDate, setendDate] = React.useState(null);

  let options = {
    qualified: qualified,
    sortBy: "createdAt:desc",
    degree: selectedDegree,
    nationality: selectedCountry,
    residence: selectedResidence,
    status: selectedStatus?.en_name,
    webUrl: selectedAd,
    startDate: startDate,
    endDate: endDate,
    source: selectedSource?.en_name?.toLowerCase(),
  };

  React.useEffect(() => {
    async function fetchData() {
      // You can await hereby
      try {
        if (qualified) {
          const students = await getLeads({
            sortBy: "createdAt:desc",
            limit: 25,
            page: page,
            qualified: true,
          });
          settotalPages(students.totalPages || null);
          setstudentsQualified(students.results);
          settotalResults(students.totalResults);
        } else {
          const studentsUnqualified = await getLeads({
            sortBy: "createdAt:desc",
            limit: 25,
            page: page,
            qualified: false,
          });
          settotalPages(studentsUnqualified.totalPages || null);
          settotalResults(studentsUnqualified.totalResults);
          setstudentsUnqualified(studentsUnqualified.results);
        }
      } catch (error) {
        alert(error);
      }
    }
    fetchData();
  }, []);

  const getQualified = async (options: object, pegeValue?: number) => {
    setpage(pegeValue ? pegeValue : 1);
    const students = await getLeads(
      Object.entries(options).reduce(
        (a: any, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
        { limit: 25, page: pegeValue ? pegeValue : 1 }
      )
    );

    settotalPages(students.totalPages || null);
    settotalResults(students.totalResults);
    setstudentsQualified(students.results);
  };

  const getUnqualified = async (options: object, pegeValue?: number) => {
    setpage(pegeValue ? pegeValue : 1);
    const studentsUnqualified = await getLeads(
      Object.entries(options).reduce(
        (a: any, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
        { limit: 25, page: pegeValue ? pegeValue : 1 }
      )
    );
    settotalPages(studentsUnqualified.totalPages || null);
    settotalResults(studentsUnqualified.totalResults);
    setstudentsUnqualified(studentsUnqualified.results);
  };

  const removeFilter = async () => {
    setfilterApplied(false);
    setselectedDegree("");
    setselectedCountry("");
    setselectedResidence("");
    setselectedStatus("");
    setselectedAd("");
    setstartDate(null);
    setendDate(null);
    setadBudget(0);
    setselectedSource("");
  };

  const applyFilters = async (isQualified: any) => {
    setfilterApplied(true);

    setpage(1);
    if (isQualified) {
      setqualified(true);
      getQualified(
        Object.entries(options).reduce(
          (a: any, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
          { limit: 25, page: 1, qualified: true }
        )
      );
    } else {
      setqualified(false);

      getUnqualified(
        Object.entries(options).reduce(
          (a: any, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
          { limit: 25, page: 1, qualified: false }
        )
      );
    }
  };

  if (!auth.isLoggedIn && !authToken) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <div
        className="d-flex justify-content-between "
        style={{ marginTop: 20 }}
      >
        <div className="d-flex flex-row align-items-center">
          <div style={{ position: "relative" }}>
            <SearchBar
              onChange={debounce(async (e: any) => {
                setsearchText(e.target.value);
                if (e.target.value === "") {
                  if (qualified) {
                    getQualified({
                      sortBy: "createdAt:desc",
                      limit: 25,
                      page: page,
                      qualified: true,
                    });
                  } else {
                    getUnqualified({
                      sortBy: "createdAt:desc",
                      limit: 25,
                      page: page,
                      qualified: false,
                    });
                  }
                } else {
                  const data = await searchLeads(
                    {
                      text: e.target.value
                        .replace(/\s+/g, "")
                        .replace(/\+/g, ""),
                    },
                    {
                      qualified: qualified,
                      sortBy: "createdAt:desc",
                      limit: 25,
                      page: 1,
                    }
                  );
                  setpage(1);
                  if (qualified) {
                    setstudentsQualified(data.results);
                  } else {
                    setstudentsUnqualified(data.results);
                  }
                  settotalResults(data.totalResults);
                  settotalPages(data.totalPages);
                }
              }, 500)}
              placeholder="Search Students, Courses"
            />
            <img
              style={{ position: "absolute", top: 11, left: 8 }}
              src={icon}
              alt="icon"
            />
          </div>
          <FilterBtn
            onClick={() => {
              if (!filterApplied) {
                setfilterModal(true);
              } else {
                removeFilter();

                if (qualified) {
                  getQualified({
                    sortBy: "createdAt:desc",
                    limit: 25,
                    page: 1,
                    qualified: true,
                    status: "",
                  });
                } else {
                  getUnqualified({
                    sortBy: "createdAt:desc",
                    limit: 25,
                    page: 1,
                    qualified: false,
                    status: "",
                  });
                }
              }
            }}
            filterApplied={filterApplied}
          >
            <FilterIcon
              color={filterApplied ? "#7F56D9" : "#98A2B3"}
              style={{ marginRight: 5 }}
            />
            {filterApplied ? "Remove Filter" : "Filter"}
          </FilterBtn>

          {adBudget > 0 && filterApplied ? (
            <div>
              <LabelText>Cost per lead:</LabelText>
              <LabelText style={{ color: "#7F56D9" }}>
                {" "}
                ${(adBudget / totalResults).toFixed(2)}
              </LabelText>
            </div>
          ) : null}
        </div>
        {/* <div className="d-flex flex-row align-items-center">
          <ToggleText>List view</ToggleText>
          <ToggleButton
            label="List"
            onChange={() => setlistView(!listView)}
            checked={listView}
          />
          <ToggleText>Application view</ToggleText>
        </div> */}
      </div>

      <div className="d-flex flex-row" style={{ marginTop: 20 }}>
        <ButtonComponent
          onClick={() => {
            if (filterApplied) {
              applyFilters(true);
            } else {
              setqualified(true);
              getQualified({
                sortBy: "createdAt:desc",
                limit: 25,
                page: page,
                qualified: true,
              });
            }

            setsearchText("");
          }}
          btnType="light"
          loading={false}
          label="Qualified"
          style={{
            marginRight: 10,
            background: qualified ? "#7F56D9" : "#fff",
            color: qualified ? "#fff" : "#000",
          }}
        />
        <ButtonComponent
          onClick={() => {
            if (filterApplied) {
              applyFilters(false);
            } else {
              setqualified(false);
              getUnqualified({
                sortBy: "createdAt:desc",
                limit: 25,
                page: page,
                qualified: false,
              });
            }

            setsearchText("");
          }}
          btnType="light"
          loading={false}
          label="Not Qualified"
          style={{
            background: !qualified ? "#7F56D9" : "#fff",
            color: !qualified ? "#fff" : "#000",
          }}
        />
      </div>

      {qualified ? (
        <div style={{ marginTop: 16 }}>
          {!listView ? (
            <React.Suspense fallback={<Loader />}>
              <LeadsView
                setselectedStatus={setselectedStatus}
                reloadStudents={(pegeValue: number) => {
                  if (qualified) {
                    getQualified(
                      {
                        sortBy: "createdAt:desc",
                        limit: 25,
                        page: page,
                        qualified: true,
                        status: selectedStatus?.en_name,
                      },
                      pegeValue
                    );
                  } else {
                    getUnqualified(
                      {
                        sortBy: "createdAt:desc",
                        limit: 25,
                        page: page,
                        qualified: false,
                        status: selectedStatus?.en_name,
                      },
                      pegeValue
                    );
                  }
                }}
                page={page}
                students={studentsQualified}
              />
            </React.Suspense>
          ) : null}
        </div>
      ) : (
        <div style={{ marginTop: 16 }}>
          <React.Suspense fallback={<Loader />}>
            <LeadsView
              setselectedStatus={setselectedStatus}
              reloadStudents={(pegeValue: number) => {
                if (qualified) {
                  getQualified(
                    {
                      sortBy: "createdAt:desc",
                      limit: 25,
                      page: page,
                      qualified: true,
                      status: selectedStatus,
                    },
                    pegeValue
                  );
                } else {
                  getUnqualified(
                    {
                      sortBy: "createdAt:desc",
                      limit: 25,
                      page: page,
                      qualified: false,
                      status: selectedStatus,
                    },
                    pegeValue
                  );
                }
              }}
              page={page}
              students={studentsUnqualified}
            />
          </React.Suspense>
        </div>
      )}

      <PaginationSection>
        <PaginationSectionText>
          {`Showing ${(page - 1) * 25 + 1} to ${
            page * 25
          } of ${totalResults} results`}
        </PaginationSectionText>
        <PaginationComponent
          onChangePrev={async () => {
            setpage(page > 1 ? page - 1 : 1);

            if (searchText) {
              const data = await searchLeads(
                { text: searchText },
                {
                  qualified: qualified,
                  sortBy: "createdAt:desc",

                  limit: 25,
                  page: page - 1,
                }
              );
              if (qualified) {
                setstudentsQualified(data.results);
              } else {
                setstudentsUnqualified(data.results);
              }
              settotalResults(data.totalResults);
              settotalPages(data.totalPages);
            } else {
              const data = await getLeads(
                Object.entries(options).reduce(
                  (a: any, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
                  { limit: 25, page: page - 1 }
                )
              );
              if (qualified) {
                setstudentsQualified(data.results);
              } else {
                setstudentsUnqualified(data.results);
              }
              settotalResults(data.totalResults);
              settotalPages(data.totalPages);
            }
          }}
          totalPage={totalPages}
          onPageClick={async (x: any) => {
            setpage(parseInt(x));

            if (searchText) {
              const data = await searchLeads(
                { text: searchText },
                {
                  qualified: qualified,
                  sortBy: "createdAt:desc",

                  limit: 25,
                  page: x,
                }
              );
              if (qualified) {
                setstudentsQualified(data.results);
              } else {
                setstudentsUnqualified(data.results);
              }
              settotalResults(data.totalResults);
              settotalPages(data.totalPages);
            } else {
              const data = await getLeads(
                Object.entries(options).reduce(
                  (a: any, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
                  { limit: 25, page: x }
                )
              );
              if (qualified) {
                setstudentsQualified(data.results);
              } else {
                setstudentsUnqualified(data.results);
              }
              settotalResults(data.totalResults);
              settotalPages(data.totalPages);
            }
          }}
          page={page}
          onChange={async () => {
            setpage(page + 1);
            if (searchText) {
              const data = await searchLeads(
                { text: searchText },
                {
                  qualified: qualified,

                  sortBy: "createdAt:desc",
                  limit: 25,
                  page: page + 1,
                }
              );
              if (qualified) {
                setstudentsQualified(data.results);
              } else {
                setstudentsUnqualified(data.results);
              }
              settotalResults(data.totalResults);
              settotalPages(data.totalPages);
            } else {
              if (
                studentsQualified?.length === 25 ||
                studentsUnqualified?.length === 25
              ) {
                const data = await getLeads(
                  Object.entries(options).reduce(
                    (a: any, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
                    { limit: 25, page: page + 1 }
                  )
                );
                if (qualified) {
                  setstudentsQualified(data.results);
                } else {
                  setstudentsUnqualified(data.results);
                }
                settotalResults(data.totalResults);
                settotalPages(data.totalPages);
              }
            }
          }}
        />
      </PaginationSection>
      {filterModal ? (
        <ModalComponent
          show={filterModal}
          handleClose={() => setfilterModal(false)}
          startDate={startDate}
          setstartDate={setstartDate}
          setendDate={setendDate}
          endDate={endDate}
          selectedAd={selectedAd}
          setselectedSource={setselectedSource}
          selectedSource={selectedSource}
          setselectedAd={setselectedAd}
          selectedDegree={selectedDegree}
          setadBudget={setadBudget}
          adBudget={adBudget}
          setselectedDegree={setselectedDegree}
          setselectedResidence={setselectedResidence}
          selectedResidence={selectedResidence}
          setselectedStatus={setselectedStatus}
          selectedStatus={selectedStatus}
          applyFilter={async () => {
            applyFilters(qualified);
          }}
          selectedCountry={selectedCountry}
          setselectedCountry={setselectedCountry}
        />
      ) : null}
    </Container>
  );
};
